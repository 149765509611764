const DOMAIN_BASE = `aims.boral.com.au`
const DOMAIN = `https://${DOMAIN_BASE}`
const AUTH_DOMAIN = `https://auth.${DOMAIN_BASE}`
const AUTH_TOKEN_URL = `${AUTH_DOMAIN}/oauth2/token`
const AUTH_USER_URL = `${AUTH_DOMAIN}/oauth2/userInfo`
const AUTHORIZE_URL = `${AUTH_DOMAIN}/oauth2/authorize`
const AUTHORIZATION_CODE = 'authorization_code'
const CLIENT_ID = '5e0jn17vp0o39d3ei6fl4hp1q9'
// const IDENTITY_PROVIDER = 'okta-dev'
const IDENTITY_PROVIDER = 'OKTA'
const REDIRECT_AUTH_URL = `${AUTHORIZE_URL}?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${DOMAIN}&identity_provider=${IDENTITY_PROVIDER}`
const BASE_API_URL = `https://6fml11ujrj.execute-api.ap-southeast-2.amazonaws.com/Stage`

const WEBSOCKET_API_URL = `wss://oyt2l3apq0.execute-api.ap-southeast-2.amazonaws.com/production`

const DEVICE_LIST = `https://api.senquip.com/cloud-api-v1/get-device-summary`
const DEVICE_INFO = `https://api.senquip.com/cloud-api-v1/get-data/`


const USER_POOL_REGION = 'ap-southeast-2'
const REFRESH_TOKEN_URL = `https://cognito-idp.${USER_POOL_REGION}.amazonaws.com/`

const HEREMAP_KEY = 'iKNVrPL3KYxJo215yfi7rVkiREppfGXzTbYQMQ_Zi1g'

export default {
  DOMAIN_BASE,
  DOMAIN,
  AUTH_DOMAIN,
  AUTH_TOKEN_URL,
  AUTH_USER_URL,
  AUTHORIZE_URL,
  AUTHORIZATION_CODE,
  CLIENT_ID,
  IDENTITY_PROVIDER,
  REDIRECT_AUTH_URL,
  BASE_API_URL,
  WEBSOCKET_API_URL,
  USER_POOL_REGION,
  REFRESH_TOKEN_URL,
  HEREMAP_KEY,
  DEVICE_LIST,
  DEVICE_INFO,
}