//@ts-nocheck
import React, { useRef, useEffect } from 'react'

function useDidUpdateEffect(fn, depsArray) {
  const didMountRef = useRef(false)

  useEffect(() => {
    if (didMountRef.current)
      fn()
    else
      didMountRef.current = true
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, depsArray)
}

export default useDidUpdateEffect